import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { navigate } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Section, Container } from "../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_PHONENUMBER = gql`
  query formSurpriseBalloonWFH2 {
    formSurpriseBalloonWFH2 {
      project
      phoneNumber
      unit
    }
  }
`

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)
  const [checked, setChecked] = useState(false)

  function handleOnChange(){
    setChecked(!checked)
  }

  const listOfProduct = [
    {value:'Aerium, Taman Permata Buana, Jakarta', label:'Aerium, Taman Permata Buana, Jakarta'},
    {value:'Akasa, Pure Living Tower Kalyana, BSD City',label:'Akasa, Pure Living Tower Kalyana, BSD City'},
    {value:'Akasa, Pure Living Tower Kirana, BSD City',label:'Akasa, Pure Living Tower Kirana, BSD City'},
    {value:'Akasa, Pure Living Tower Kamaya, BSD City',label:'Akasa, Pure Living Tower Kamaya, BSD City'},
    {value:'Asatti, Garden House Agate Blue, BSD City',label:'Asatti, Garden House Agate Blue, BSD City'},
    {value:'Asatti, Garden House Agate Brown, BSD City',label:'Asatti, Garden House Agate Brown, BSD City'},
    {value:'Asatti, Garden House Agate Green, BSD City',label:'Asatti, Garden House Agate Green, BSD City'},
    {value:'Asatti, Garden House Agate Orange, BSD City',label:'Asatti, Garden House Agate Orange, BSD City'},
    {value:'Asatti, Garden House Agate Purple, BSD City',label:'Asatti, Garden House Agate Purple, BSD City'},
    {value:'Asatti, Garden House Agate Red, BSD City',label:'Asatti, Garden House Agate Red, BSD City'},
    {value:'Asatti, Garden House Agate Yellow, BSD City',label:'Asatti, Garden House Agate Yellow, BSD City'},
    {value:'Asatti, Garden House Aventurine Blue, BSD City',label:'Asatti, Garden House Aventurine Blue, BSD City'},
    {value:'Asatti, Garden House Aventurine Green, BSD City',label:'Asatti, Garden House Aventurine Green, BSD City'},
    {value:'Asatti, Garden House Aventurine Yellow, BSD City',label:'Asatti, Garden House Aventurine Yellow, BSD City'},
    {value:'Asatti, Garden House Carnelian, BSD City',label:'Asatti, Garden House Carnelian, BSD City'},
    {value:'Asatti, Garden House Chalcedony Red, BSD City',label:'Asatti, Garden House Chalcedony Red, BSD City'},
    {value:'Asatti, Garden House Chalcedony White, BSD City',label:'Asatti, Garden House Chalcedony White, BSD City'},
    {value:'Banjar Wijaya, Alden, Tangerang',label:'Banjar Wijaya, Alden, Tangerang'},
    {value:'Banjar Wijaya, Cattleya, Tangerang',label:'Banjar Wijaya, Cattleya, Tangerang'},
    {value:'Banjar Wijaya, Hazel, Tangerang',label:'Banjar Wijaya, Hazel, Tangerang'},
    {value:'Banjar Wijaya, Malberry, Tangerang',label:'Banjar Wijaya, Malberry, Tangerang'},
    {value:'Casa De Parco, Tower Cassea, BSD City',label:'Casa De Parco, Tower Cassea, BSD City'},
    {value:'Casa De Parco, Tower Magnolia, BSD City',label:'Casa De Parco, Tower Magnolia, BSD City'},
    {value:'De Park DeMaja, BSD City',label:'De Park DeMaja, BSD City'},
    {value:'De Park Jadeite, BSD City',label:'De Park Jadeite, BSD City'},
    {value:'Grand City, Forestville, Balikpapan',label:'Grand City, Forestville, Balikpapan'},
    {value:'Grand City, Hayfield, Balikpapan',label:'Grand City, Hayfield, Balikpapan'},
    {value:'Grand City, Ruko Golden Boulevard',label:'Grand City, Ruko Golden Boulevard'},
    {value:'Greenwich Park, Caelus, BSD City',label:'Greenwich Park, Caelus, BSD City'},
    {value:'Greenwich Park, Luxmore, BSD City',label:'Greenwich Park, Luxmore, BSD City'},
    {value:'Greenwich Park, Mayfield, BSD City',label:'Greenwich Park, Mayfield, BSD City'},
    {value:'Greenwich Park, Sheffield, BSD City',label:'Greenwich Park, Sheffield, BSD City'},
    {value:'Greenwich Park ext, Fleekhauz R, BSD City',label:'Greenwich Park ext, Fleekhauz R, BSD City'},
    {value:'Kios Pasar Modern Intermoda, BSD City',label:'Kios Pasar Modern Intermoda, BSD City'},
    {value:'Klaska Residence, Surabaya',label:'Klaska Residence, Surabaya'},
    {value:'Kota Wisata, Nashville, Cibubur',label:'Kota Wisata, Nashville, Cibubur'},
    {value:'Kota Wisata, Visalia, Cibubur',label:'Kota Wisata, Visalia, Cibubur'},
    {value:'Legenda Wisata, Acropolis',label:'Legenda Wisata, Acropolis'},
    {value:'Legenda Wisata, Da Vinci',label:'Legenda Wisata, Da Vinci'},
    {value:'Legenda Wisata, Vasco Da Gama',label:'Legenda Wisata, Vasco Da Gama'},
    {value:'Legenda Wisata, Washington',label:'Legenda Wisata, Washington'},
    {value:'Nava Park, BSD City',label:'Nava Park, BSD City'},
    {value:'Nuvasa Bay The Nove Kav.ph.1, Batam',label:'Nuvasa Bay The Nove Kav.ph.1, Batam'},
    {value:'Nuvasa Bay The Nove Landed ph.1, Batam',label:'Nuvasa Bay The Nove Landed ph.1, Batam'},
    {value:'Nuvasa Bay The Nove Kaina, Batam',label:'Nuvasa Bay The Nove Kaina, Batam'},
    {value:'Nuvasa Bay The Nove Kalani, Batam',label:'Nuvasa Bay The Nove Kalani, Batam'},
    {value:'Ruko CommPark, Kota Wisata Cibubur',label:'Ruko CommPark, Kota Wisata Cibubur'},
    {value:'Ruko Pasar Modern Intermoda, BSD City',label:'Ruko Pasar Modern Intermoda, BSD City'},
    {value:'Ruko Provence Suites, BSD City',label:'Ruko Provence Suites, BSD City'},
    {value:'Southgate Tower Altuera',label:'Southgate Tower Altuera'},
    {value:'Southgate Tower Elegance',label:'Southgate Tower Elegance'},
    {value:'Southgate Tower Prime',label:'Southgate Tower Prime'},
    {value:'The Elements, Tower Harmony, Jakarta',label:'The Elements, Tower Harmony, Jakarta'},
    {value:'The Elements, Tower Serenity, Jakarta',label:'The Elements, Tower Serenity, Jakarta'},
    {value:'The Eminent, Vivacia, BSD City',label:'The Eminent, Vivacia, BSD City'},
    {value:'The Icon, Business Park 5, BSD City',label:'The Icon, Business Park 5, BSD City'},
    {value:'The Mozia, Amarine, BSD City',label:'The Mozia, Amarine, BSD City'},
    {value:'The Mozia, Avezza, BSD City',label:'The Mozia, Avezza, BSD City'},
    {value:'The Mozia, Piazza, BSD City',label:'The Mozia, Piazza, BSD City'},
    {value:'Upper West, BSD City',label:'Upper West, BSD City'},
    {value:'Vanya Park, Alesha, BSD City',label:'Vanya Park, Alesha, BSD City'},
    {value:'Vanya Park, Anarta, BSD City',label:'Vanya Park, Anarta, BSD City'},
    {value:'Vanya Park, Assana, BSD City',label:'Vanya Park, Assana, BSD City'},
    {value:'Vanya Park, Azura, BSD City',label:'Vanya Park, Azura, BSD City'},
    {value:'Vanya Park, West Park, BSD City',label:'Vanya Park, West Park, BSD City'},
    {value:'Wisata Bukit Mas, Surabaya',label:'Wisata Bukit Mas, Surabaya'},
  ];
  
  return (
    <Form className="row pt-4" id="surpriseForm">
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nama</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder="Nama Anda"
          type="text"
          id="customerName"
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nomor Handphone</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder="Nomor Handphone"
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={(val) =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          id="customerPhone"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder="Email Anda"
          type="text"
          id="customerEmail"
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Produk Yang Dibeli</label>
        <Select
          className="form-control px-0"
          classNamePrefix="Produk Yang Dibeli"
          options={listOfProduct}
          defaultValue={{ value: "", label: "Produk Yang Dibeli" }}
          id="product"
          onChange={(e) => {
            setFieldValue("product", e.value)
            sessionStorage.setItem("product", e.value)
          }}
        />
        {touched.product && errors.product && (
          <div className="invalid-value d-block">{errors.product}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nomor Blok/Lantai (Note: Input tanpa spasi)</label>
        <Field
          name="noBlock"
          className="form-control px-0"
          placeholder="Contoh: B1 atau 1B"
          type="text"
          id="noBlock"
        />
        {touched.noBlock && errors.noBlock && (
          <div className="invalid-value d-block">{errors.noBlock}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nomor Unit (Note: Input tanpa spasi)</label>
        <Field
          name="noUnit"
          className="form-control px-0"
          placeholder="Contoh: A52 atau 52A"
          type="text"
          id="noUnit"
        />
        {touched.noUnit && errors.noUnit && (
          <div className="invalid-value d-block">{errors.noUnit}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nama Sales</label>
        <Field
          name="salesName"
          className="form-control px-0"
          placeholder="Nama sales"
          type="text"
          id="salesName"
        />
        {touched.salesName && errors.salesName && (
          <div className="invalid-value d-block">{errors.salesName}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">
          <Field 
            type="checkbox" 
            name="checked" 
            value="Agent" 
            onClick={handleOnChange}
          />
          &nbsp;Agent
        </label>
      </div>
      <div className={checked ? "col-12 col-md-12 pb-3 d-block" : "col-12 col-md-12 pb-3 d-none"}>
        <label className="text-muted my-0">Nama Kantor</label>
        <Field
          name="officeName"
          className="form-control px-0"
          placeholder="Nama Kantor"
          type="text"
          id="officeName"
        />
        {touched.officeName && errors.officeName && (
          <div className="invalid-value d-block">{errors.officeName}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3 pt-3" id="disclaimer">
        <label className="text-muted my-0">
          <Field 
            type="checkbox" 
            name="isAgree" 
            value="isAgree" 
            id="isAgree"
          />
          &nbsp;Saya menyetujui bahwa keputusan Extra Surprise ini tidak dapat diganggu gugat
        </label>
        {touched.isAgree && errors.isAgree && (
          <div className="invalid-value d-block">{errors.isAgree}</div>
        )}
      </div>
      <div className="col-12 pt-5">
        <button className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
      </div>
    </Form>
  )
}

const SurpriseBalloonPage = (location) =>{
  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  
  useEffect(() => {
    if (location.uri == "/extrasurprise") {
      let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
      
      if(resizeScreen){
        document.getElementsByClassName("logo-secondary")[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      }else{
        document.getElementsByClassName("logo-secondary")[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
    }
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    product: "",
    noBlock: "",
    noUnit: "",
    salesName: "",
    isAgree: "",
    officeName: "",
  })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, product, noBlock, noUnit, salesName, isAgree, officeName}) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        product: formValues.product || product,
        noBlock: formValues.noBlock || noBlock,
        noUnit: formValues.noUnit || noUnit,
        salesName: formValues.salesName || salesName,
        isAgree: formValues.isAgree || isAgree,
        officeName: formValues.officeName || officeName,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      noBlock: Yup.string().required("No block is required"),
      noUnit: Yup.string().required("No unit is required"),
      salesName: Yup.string().required("Nama sales is required"),
      isAgree: Yup.string().required("Disclaimer is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      let dataList = data.formSurpriseBalloonWFH2
      let counter = 0

      for (let i = 0; i < dataList.length; i++) {

        if ((dataList[i].phoneNumber == values.phoneNumber.split("+").join("")) && (dataList[i].project.trim() == values.product.trim())) {
          console.log(">>1")
          if(dataList[i].unit.trim() == values.noUnit.trim()){
            console.log(">>2")
            alert("Maaf! Anda tidak bisa mengikuti program Extra Surprise kembali")
            return false
          }else{
            console.log(">>3")
            counter = counter + 1
          }
        }

        if(counter == 2){
          console.log(">>4")
          alert("Maaf! Anda tidak bisa mengikuti program Extra Surprise kembali")
          return false
        }
      }

        console.log(">>5")
        let form = document.getElementById("surpriseForm")
        for (let q = 0; q < form.elements.length; q++) {
          let eSecond = form.elements[q]
          if (eSecond.type === "button") continue
          let idSecond = eSecond.id
          var valueSecond = eSecond.value
          sessionStorage.setItem(idSecond, valueSecond)
        }
        navigate("/extrasurprise/pop")

    },
  })(LeadForm)
  return(
    <Layout location={location} currentLocation={location.uri}>
      <SEO title="Extra Surprise" description="" />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <Section className="mainSurprise bg-gray-50">
        <Container className="surpriseForm">
          <EnhancedLeadForm />
        </Container>
      </Section>
    </Layout>
  )
}
export default SurpriseBalloonPage
